#home {
  width: 100%;
  height: 94vh;
  background: url("../assets/img/banner/banner.jpg");
   background-repeat: no-repeat; 
     background-attachment: scroll;
     background-position: center center; 
     background-size: cover; 
}
.text{
  color:#ffffffe8;
  font-size: 1.7vw;
  /*margin: -200px*/
}


#home .container {
  padding-top: 72px;
  
}
.container {
  max-width: 80%;
  
}

#home h1 {
  margin: 0 0 10px 0;
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  color: #fff;
}

#home h2 {
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 500px;
  font-size: 24px;
}
.cardbg{
  background-color: #424c5891!important;
}

#home .btn-get-started {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #47b2e4;
}

#home .btn-get-started:hover {
  background: #209dd8;
}

#home .btn-watch-video {
  font-size: 16px;
  display: inline-block;
  padding: 10px 0 8px 40px;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  position: relative;
}

#home .btn-watch-video i {
  color: #fff;
  font-size: 32px;
  position: absolute;
  left: 0;
  top: 7px;
  transition: 0.3s;
}

#home .btn-watch-video:hover i {
  color: #47b2e4;
}

#hero .animated {
  /*animation: up-down 2s ease-in-out infinite alternate-reverse both;*/
}

@media (max-width: 991px) {
  #home {
    height: 100vh;
    text-align: center;
  }
  #home .animated {
    -webkit-animation: none;
    animation: none;
  }
  #home .hero-img {
    text-align: center;
  }
  #home .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #home h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #home h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #home .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #home .hero-img img {
    width: 80%;
  }
  #home .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
  #home .btn-watch-video {
    font-size: 16px;
    padding: 10px 0 8px 40px;
    margin-left: 20px;
  }
  #home .btn-watch-video i {
    font-size: 32px;
    top: 7px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/*scroll from menu*/
@media only screen and (min-width: 1536px) { 
section#services{
  height: 55vw;
}
section#clients{
height: 55vw;
}
section#technology{
height: 55vw;
}
section#career{
height: 55vw;
}
section#about{
  height:47vw;
}
.form-control{
  font-size: 0.9vw;
}
.btn{
  font-size: 0.9vw;
}

 }

