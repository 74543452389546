/*@media (min-width: 1200px)
.container, .container-lg, .container-md, .container-sm, .container-xl {
     max-width: 1140px; 
}*/
/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px;
          transition: all 0.5s ease 0s;

          .card-title {
    margin-bottom: .75rem;
    color: #f99100;
}

#f6f6f69c

}
*/
body {
  font-family: "Verdana", Verdana;
  color: #444444;
  font-size: 0.9vw!important;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    transition: all 0.5s ease 0s;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 15px;
    
    /* box-shadow: 0 0 24px 0 rgb(0 0 0 / 10%); */
}
p {
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: "Verdana", Verdana;
    font-size: 0.9vw
}

a {
  color: #47b2e4;
  text-decoration: none;
   font-family: "Verdana", Verdana;
   font-size: smaller;
}

a:hover {
  color: #FDB913;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Verdana", sans-serif;
}
.h5, h5 {
    font-size: 1.4vw!important;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: auto;
  }
}
/* @media (min-width: 1600px){

}
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1500px;
} */
/* 
@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1500px;
  }
} */

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #37517e;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #37517e;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #47b2e4;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #209dd8;
  color: #fff;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}

#header.header-scrolled, #header.header-inner-pages {
  background: rgba(40, 58, 90, 0.9);
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}

#header .logo a {
  color: #fff;
}

#header .logo img {
  max-height: 40px;
}
.section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
 } 
  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #47b2e4;
    bottom: 0;
    left: calc(50% - 20px);
}


section {
  padding: 5vw 0;
  overflow: hidden;
}


.section-title {
  text-align: center;
  padding-bottom: 2vw;
  font-size: 1vw;

}

.section-title h2 {
  font-size: 1.7vw;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: rgb(22 73 125);
}


.section-title h2::before {
  margin: 0 15px 10px 0;
}

.section-title h2::after {
  margin: 0 0 10px 15px;
}

.section-title p {
  margin: 15px 0 0 0;
}




/*--------------------------------------------------------------
# Datascience
--------------------------------------------------------------*/

.rowds {
  display: flex;
  flex-wrap: wrap;
  margin-right: 50px;
  margin-left: 65px;
}
